@import '~antd/dist/antd.css';

.App {
	text-align: center;
	justify-content: center;
	font-size: 20px;
}

/* Nav Bar styles */
.navbar {
	transition: background-color 0.4s;
	position: fixed;
	top: 0;
	width: 100%;
	padding: 0.5rem;
	background-color: white;
	z-index: 2;
	backdrop-filter: blur(2px);
}

.navopened {
	background-color: #ffffff !important;
}

.nav-logo {
	height: 100%;
	width: 100%;
}

.navbar .logo {
	transition: all 0.2s;
	align-items: center;
	align-content: center;
	color: rgb(243, 243, 243);
	letter-spacing: 2px;
	font-weight: 400;
	margin: 0;
	font-size: 28px;
	padding-top: 10px;
	width: 200px;
	min-width: 200px;
}

.nav-logo:hover {
	cursor: pointer;
}

.navbar .nav-content {
	max-width: auto;
	margin-inline: auto;
	width: auto;
	display: flex;
	align-items: center;
	height: 100%;
	padding-left: var(--bs-gutter-x, 0.75rem);
	padding-right: var(--bs-gutter-x, 0.75rem);
	margin-right: 84px;
	margin-left: 84px;
	justify-content: center;
}

.nav-item {
	display: inline;
	margin-left: 2rem;
	color: rgb(0, 0, 0);
	width: 100%;
}

.nav-item:hover {
	color: black;
	cursor: pointer;
}

.bar {
	margin-left: 0;
	margin-right: auto;
	justify-content: center;
	margin-top: auto;
}

.bar-menu {
	margin-left: 0;
	margin-right: auto;
	justify-content: center;
	display: grid;
}

.navbar ul li a {
	transition: all 0.2s;
	font-size: 20px;
	cursor: pointer;

	text-decoration: none;
	color: rgb(0, 0, 0);
	font-weight: 500;
}

/* Home styles */
header {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

header h1 {
	z-index: 1;
	color: white;
	font-size: 64px;
}

video {
	top: 0;
	object-fit: cover;
	width: 100%;
	height: 100vh;
	position: absolute;
	z-index: -1;
}

.headeropacity {
	position: absolute;
	width: 100%;
	height: 100vh;
	z-index: 0;
	top: 0;
	background-color: black;
	opacity: 0.5;
}

.anticon-menu {
	color: black;
}

/* About styles */
.container {
	width: auto;
}

.about {
	margin-top: 15rem;
	margin-bottom: 15rem;
}

/* Services styles */
.container {
	width: auto;
}

.services {
	margin-top: 15rem;
	margin-bottom: 15rem;
}
.card-row {
	display: flex;
	margin: auto;
}

div .ant-card-meta-title {
	display: contents;
	padding: 10px;
	white-space: pre-line;
}

div .ant-card-meta-title {
	font-weight: bold;
	padding: 10px;
}

div .ant-card-meta-description {
	padding: 10px;
	text-align: left;
	color: rgba(0, 0, 0, 0.75);
}

.content-page-services {
	width: 80%;
	margin: auto;
}

div .ant-card-bordered {
	border-radius: 8px;
}

.card-service {
	max-width: 500px;
	min-width: 500px;
	width: auto;
	margin: 10px;
	margin-top: 30px;
}

.card-service .image-carousel img {
	max-height: 320px;
	min-height: 320px;
}

.card-service-list {
	padding-left: 40px;
	padding-right: 40px;
}
.ant-card-meta-detail {
	width: 100%;
}

/* Footer styles */
footer .logo {
	transition: all 0.2s;
	align-items: center;
	align-content: center;
	padding-top: 10px;
	width: 200px;
	min-width: 200px;
	margin-left: 20px;
	margin-right: 20px;
}

footer {
	background-color: white;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
}

footer .row {
	align-items: flex-start;
}

footer ul {
	list-style: none;
	margin-left: 20px;
	margin-right: 20px;
	padding-left: 0;
	margin-top: 2.5px;
}
footer ul li a {
	text-decoration: none;
	color: #000000;
	font-size: 18px;
	font-weight: 500;
}

.footer-title {
	color: #000000;
	font-weight: 600;
	font-size: 22px;
	margin-bottom: 0;
	word-wrap: initial;
	margin-left: 20px;
	margin-right: 20px;
}

/* Common styles */
.row {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.content-page {
	width: 40%;
}

@media (max-width: 1700px) {
	.card-service {
		max-width: 350px;
		min-width: 350px;
		width: auto;
		margin: 10px;
		margin-top: 30px;
	}
	.card-service .image-carousel img {
		max-height: 230px;
		min-height: 230px;
	}
}

@media (max-width: 1200px) {
	.card-service {
		max-width: 250px;
		min-width: 250px;
		width: auto;
		margin: 10px;
		margin-top: 30px;
	}
	.card-service .image-carousel img {
		max-height: 170px;
		min-height: 170px;
	}
}

@media (max-width: 800px) {
	.row {
		display: inline-block;
		align-items: center;
		justify-content: center;
	}
	header h1 {
		font-size: 44px;
	}

	.content-page {
		margin-top: 20px;
		width: 100%;
	}

	.container {
		max-width: 600px;
	}
	footer .logo {
		transition: all 0.2s;
		align-items: center;
		align-content: center;
		padding-top: 10px;
		width: 200px;
		min-width: 200px;
		margin: auto;
	}
	.about {
		margin-left: auto;
		margin-right: auto;
	}
	.services {
		margin-left: auto;
		margin-right: auto;
	}
	.navbar .logo {
		margin: auto;
	}
	.bar {
		padding-left: 0;
		margin-top: auto;
	}
	.navbar .nav-content {
		margin-right: 20px;
		margin-left: 20px;
	}
	.card-row {
		display: inline-block;
	}
	.card-service {
		max-width: 350px;
		min-width: 250px;
		width: auto;
		margin: 10px;
		margin-top: 30px;
	}
	.card-service .image-carousel img {
		max-height: 230px;
		min-height: 230px;
	}
}

@media (max-width: 500px) {
	.card-service {
		min-width: 250px;
		width: auto;
		margin: 10px;
		margin-top: 30px;
	}
	.card-service .image-carousel img {
		max-height: 170px;
		min-height: 170px;
	}
}
